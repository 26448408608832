import { render, staticRenderFns } from "./Calls.vue?vue&type=template&id=574e35dc&scoped=true&"
import script from "./Calls.vue?vue&type=script&lang=js&"
export * from "./Calls.vue?vue&type=script&lang=js&"
import style0 from "@/assets/css/tables/table-basic.css?vue&type=style&index=0&id=574e35dc&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "574e35dc",
  null
  
)

export default component.exports